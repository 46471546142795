import 'react-app-polyfill/ie9'; // For IE 9-11 support
import 'react-app-polyfill/stable';
// import 'react-app-polyfill/ie11'; // For IE 11 support
import './polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import App from './App';
import { ENV } from './utils/configuration';

if (ENV !== 'localhost') {
  Sentry.init({
    dsn: 'https://8ff328dbdafb4a41ad4594dde39c4da6@o554202.ingest.sentry.io/5682440',
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
