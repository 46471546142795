import { get, post } from '../utils/request';
import { credentials } from './user';

export const handleFetchCompanyLogo = (user) => async (dispatch) => {
  try {
    const response = await get('/company_logo', dispatch, credentials(user));

    const body = await response.json();

    const { url } = body;

    dispatch(setCompanyLogoUrl(url));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleImportCompanyLogo = (params, user) => async (dispatch) => {
  try {
    const response = await post('/company_logo', params, dispatch, credentials(user));

    const body = await response.json();

    const { url } = body;

    dispatch(setCompanyLogoUrl(url));

    return true;
  } catch (error) {
    return false;
  }
};

export const setCompanyLogoUrl = (url) => ({
  type: 'SET_COMPANY_LOGO_URL',
  url,
});
