import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import withUser from './containers/withUser';

class ProtectedRouteSubscribe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      subscribe: '',
    };
  }

  async componentDidMount() {
    const { user } = this.props;
    const { subscribe } = user;

    await this.setState({ subscribe, isLoading: false });
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const { subscribe, isLoading } = this.state;

    if (isLoading) {
      return null;
    }

    if (!isLoading) {
      return (
        <div>
          <Route
            {...rest}
            render={(props) => {
              if (subscribe === false) return <Component {...props} />;
              return <Redirect to="/" />;
            }}
          />
        </div>
      );
    }
  }
}

export default withUser(ProtectedRouteSubscribe);
