const initialState = {
  profits: [],
};

const Steps = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROFIT_LIST':
      return {
        profits: action.profits,
      };
    default:
      return state;
  }
};

export default Steps;
