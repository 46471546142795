const initialState = {
  tasks: [],
};

const Tasks = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_TASK_LIST':
      return {
        tasks: action.tasks,
      };
    default:
      return state;
  }
};

export default Tasks;
