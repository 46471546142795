import { apiBaseUrl, jsonTypeHeaders } from './configuration';

export const destroy = (url, dispatch, additional_headers = {}) => request('delete', url, {}, dispatch, additional_headers);

export const get = (url, dispatch, additional_headers = {}) => request('get', url, null, dispatch, additional_headers);

export const post = (url, params, dispatch, additional_headers = {}) => request('post', url, params, dispatch, additional_headers);

export const put = (url, params, dispatch, additional_headers = {}) => request('put', url, params, dispatch, additional_headers);

const request = async (method, url, params, dispatch, additionalHeaders = {}) => {
  let body = null;
  if (method !== 'get') {
    body = JSON.stringify(params);
  }

  return await fetch(apiBaseUrl + url,
    {
      body,
      method,
      headers: { ...jsonTypeHeaders, ...additionalHeaders },
    })
    .catch((error) => {
      console.log(error);
    });
};
