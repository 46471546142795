import { get, post, put } from '../utils/request';
import { credentials } from './user';

export const handleFetchProviderList = (user) => async (dispatch) => {
  try {
    const response = await get('/provider', dispatch, credentials(user));
    const body = await response.json();

    const { providers } = body;

    dispatch(setProviderList(providers));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleCreateNewProviderList = (params, user) => async (dispatch) => {
  try {
    const response = await post('/provider', params, dispatch, credentials(user));
    const body = await response.json();

    const { providers } = body;

    dispatch(setProviderList(providers));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleDeleteProviderList = (idsToDelete, user) => async (dispatch) => {
  try {
    const params = {
      ids: idsToDelete,
    };

    const response = await post('/delete_provider', params, dispatch, credentials(user));
    const body = await response.json();

    const { providers } = body;

    dispatch(setProviderList(providers));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleUpdateProviderList = (params, productId, user) => async (dispatch) => {
  try {
    const response = await put(`/provider/${productId}`, params, dispatch, credentials(user));
    const body = await response.json();

    const { providers } = body;

    dispatch(setProviderList(providers));

    return true;
  } catch (error) {
    return false;
  }
};

export const setProviderList = (providerList) => ({
  type: 'SET_PROVIDER_LIST',
  providerList,
});
