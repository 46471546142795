import { combineReducers } from 'redux';
import submissions from './submissions';
import priceList from './priceList';
import user from './user';
import events from './events';
import steps from './steps';
import tasks from './tasks';
import profits from './profits';
import providerList from './providerList';
import Plans from './plans';
import StoreProducts from './storeProducts';
import companyLogo from './companyLogo';
import ProviderSubmissions from './providerSubmissions';

const rootReducer = combineReducers({
  submissions,
  priceList,
  user,
  events,
  steps,
  tasks,
  profits,
  providerList,
  companyLogo,
  Plans,
  StoreProducts,
  ProviderSubmissions,
});

export default rootReducer;
