const initialState = {
  providerSubmissions: [],
};

const ProviderSubmissions = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROVIDER_SUBMISSIONS':
      return {
        providerSubmissions: action.providerSubmissions,
      };
    default:
      return state;
  }
};

export default ProviderSubmissions;
