import { get, post, put } from '../utils/request';
import { credentials } from './user';

export const handleFetchPriceList = (user) => async (dispatch) => {
  try {
    const response = await get('/product_list', dispatch, credentials(user));
    const body = await response.json();

    const { priceList } = body;

    dispatch(setPriceList(priceList));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleCreateNewProductList = (params, user) => async (dispatch) => {
  try {
    const response = await post('/product_list', params, dispatch, credentials(user));
    const body = await response.json();

    const { priceList } = body;

    dispatch(setPriceList(priceList));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleImportProductListFromExcel = (params, user) => async (dispatch) => {
  try {
    const response = await post('/import_product_list', params, dispatch, credentials(user));
    const body = await response.json();

    const { priceList } = body;

    dispatch(setPriceList(priceList));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleDeleteProductList = (idsToDelete, user) => async (dispatch) => {
  try {
    const params = {
      ids: idsToDelete,
    };

    const response = await post('/delete_product_list', params, dispatch, credentials(user));
    const body = await response.json();

    const { priceList } = body;

    dispatch(setPriceList(priceList));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleUpdateProductList = (params, productId, user) => async (dispatch) => {
  try {
    const response = await put(`/product_list/${productId}`, params, dispatch, credentials(user));
    const body = await response.json();

    const { priceList } = body;

    dispatch(setPriceList(priceList));

    return true;
  } catch (error) {
    return false;
  }
};

export const setPriceList = (priceList) => ({
  type: 'SET_PRICE_LIST',
  priceList,
});
