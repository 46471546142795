export const jsonTypeHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
};

/// Production
export const ENV = 'production';
export const apiBaseUrl = 'https://soumigo-production.app/api/v1';
export const redirectUrl = 'https://www.soumigo.app/reset_password';
export const StripePromise = 'pk_live_51I205wKCZzXeTiav8nIR9vFx3JtQUp4DkXbdUXQ2xZKKR8j6rRlgzKV2030qGIGFZTd7aqWZHjH7JOLaxEO7WhqC00qiU45Spe'

// Staging
// export const ENV = 'staging';
// export const apiBaseUrl = 'https://soumigo-staging.app/api/v1';
// export const redirectUrl = 'https://staging.d1lr6ml3ulcjbn.amplifyapp.com/reset_password';
// export const StripePromise = 'pk_live_51I205wKCZzXeTiav8nIR9vFx3JtQUp4DkXbdUXQ2xZKKR8j6rRlgzKV2030qGIGFZTd7aqWZHjH7JOLaxEO7WhqC00qiU45Spe';

// Development
// export const ENV = 'localhost';
// export const apiBaseUrl = 'http://localhost:3001/api/v1';
// export const redirectUrl = 'http://localhost:3000/reset_password';
// export const StripePromise = 'pk_test_51I205wKCZzXeTiavlgwynvBuFoB48YCNW8BzX6LHAj4fYVy641nY21TBFvqHPMfS1PtOQ64gjoHb3e7VgVIaGSey00Aww2I8Dl';
