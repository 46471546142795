const initialState = {
  steps: [],
};

const Steps = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STEP_LIST':
      return {
        steps: action.steps,
      };
    default:
      return state;
  }
};

export default Steps;
