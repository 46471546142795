const initialState = {
  providerList: [],
};

const providerList = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PROVIDER_LIST':
      return {
        providerList: action.providerList,
      };
    default:
      return state;
  }
};

export default providerList;
