const initialState = {};

const user = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_USER':
      return {
        ...state,
        ...action.user,
      };
    case 'REMOVE_USER':
      return {
        initialState,
      };
    case 'SET_AUTH_INFOS':
      return {
        ...state,
        accessToken: action.accessToken,
        client: action.client,
        signedIn: true,
        uid: action.uid,
      };
    case 'SET_USER_CATEGORIES':
      return {
        ...state,
        categories: action.categories,
      };
    case 'SET_USER_SUB_CATEGORIES':
      return {
        ...state,
        subCategories: action.subCategories,
      };
    case 'SET_USER_PRODUCT_LIST_CATEGORIES':
      return {
        ...state,
        productListCategories: action.productListCategories,
      };
    case 'SIGN_OUT':
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

export default user;
