import { post } from '../utils/request';
import { credentials } from './user';

export const handleFetchStoreProducts = (params, user) => async (dispatch) => {
  try {
    const response = await post('/search_store_product', params, dispatch, credentials(user));

    const body = await response.json();

    const { results } = body;

    dispatch(setStoreProducts(results));

    return true;
  } catch (error) {
    return false;
  }
};

export const setStoreProducts = (storeProducts) => ({
  type: 'SET_STORE_PRODUCTS',
  storeProducts,
});

export const resetStoreProducts = () => ({
  type: 'RESET_STORE_PRODUCTS',
});
