import {
  get, post, put,
} from '../utils/request';
import { credentials } from './user';
import { setPriceList } from './priceList';

export const handleFetchSubmissions = (user) => async (dispatch) => {
  try {
    const response = await get('/submission', dispatch, credentials(user));
    const body = await response.json();

    const { data } = body;

    await dispatch(setSubmissions(data));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleCreateNewSubmission = (params, user) => async (dispatch) => {
  try {
    const response = await post('/submission', params, dispatch, credentials(user));

    const body = await response.json();
    const { submissions } = body;

    if (submissions) dispatch(setSubmissions(submissions));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleDeleteSubmission = (user, idsToDelete, submissionId) => async (dispatch) => {
  try {
    const params = {
      ids: idsToDelete,
    };

    const response = await post('/delete_submission', params, dispatch, credentials(user));

    const body = await response.json();
    const { submissions } = body;

    if (submissions) dispatch(setSubmissions(submissions));

    if (idsToDelete.includes(submissionId) || submissions.length === 0) {
      dispatch(resetActiveSubmission());
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const handleDeleteActiveSubmissionProducts = (params, user) => async (dispatch) => {
  try {
    const response = await post('/delete_products', params, dispatch, credentials(user));

    const body = await response.json();

    if (body) await dispatch(setActiveSubmissionAfterProductDeleteOrCreate(body));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleUpdateActiveSubmissionProduct = (params, user, id) => async (dispatch) => {
  try {
    const response = await put(`/product/${id}`, params, dispatch, credentials(user));

    const body = await response.json();
    if (body) await dispatch(setActiveSubmissionAfterProductDeleteOrCreate(body));
    return true;
  } catch (error) {
    return false;
  }
};

export const handleSetPriorityToProductByCategory = (params, user) => async (dispatch) => {
  try {
    const response = await post('/order_submission_products', params, dispatch, credentials(user));

    const body = await response.json();

    if (body) dispatch(setActiveSubmissionProductAfterPriorityChange(body));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleAddNewProductToSubmission = (params, user) => async (dispatch) => {
  try {
    const response = await post('/product', params, dispatch, credentials(user));

    const body = await response.json();
    const { dispatchPriceList, priceList } = body;

    if (body) dispatch(setActiveSubmissionAfterProductDeleteOrCreate(body));
    if (dispatchPriceList) dispatch(setPriceList(priceList));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleSetActiveSubmission = (user, submissionId) => async (dispatch) => {
  try {
    if (submissionId !== '-1') {
      const response = await get(`/submission/${submissionId}`, dispatch, credentials(user));
      const body = await response.json();

      if (body) await dispatch(setActiveSubmission(body));
    } else {
      dispatch(resetActiveSubmission());
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const handleSetActiveSubmissionClientInformations = (user, params, submissionId) => async (dispatch) => {
  try {
    const response = await put(`/submission/${submissionId}`, params, dispatch, credentials(user));

    const body = await response.json();
    const { data } = body;

    if (data) await dispatch(setActiveSubmissionAfterClientInfoUpdate(data));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleGeneratePdf = (params, user) => async (dispatch) => {
  try {
    const response = await post('/generate_pdf', params, dispatch, credentials(user));
    const body = await response.json();
    const { status } = body;

    return status === 200;
  } catch (error) {
    return false;
  }
};

export const setActiveSubmissionAfterClientInfoUpdate = (clientInfo) => ({
  type: 'SET_ACTIVE_SUBMISSION_AFTER_CLIENT_INFO_UPDATE',
  clientInfo,
});

export const setActiveSubmissionAfterProductDeleteOrCreate = (submission) => ({
  type: 'SET_ACTIVE_SUBMISSION_AFTER_PRODUCT_DELETE_OR_CREATE',
  submission,
});

export const setActiveSubmissionProductAfterPriorityChange = (submission) => ({
  type: 'SET_ACTIVE_SUBMISSION_PRODUCTS_AFTER_PRIORITY_CHANGE',
  submission,
});

export const setActiveSubmission = (submission) => ({
  type: 'SET_ACTIVE_SUBMISSION',
  submission,
});

export const resetActiveSubmission = () => ({
  type: 'RESET_ACTIVE_SUBMISSION',
});

export const setSubmissions = (submissionList) => ({
  type: 'SET_SUBMISSIONS',
  submissionList,
});
