import {
  post, get, put, destroy,
} from '../utils/request';
import { setSubmissions, resetActiveSubmission } from './submission';
import { setPriceList } from './priceList';
import { setProviderList } from './providerList';
import { setCompanyLogoUrl } from './companyLogo';
import { redirectUrl } from '../utils/configuration';
import { resetStoreProducts } from './storeProducts';

export const signUp = (params) => async () => {
  try {
    const response = await post('/auth', params);

    const body = await response.json();

    return body;
  } catch (error) {
    return false;
  }
};

export const signIn = (username, password) => async (dispatch) => {
  try {
    const params = {
      email: username,
      password,
    };

    const response = await post('/auth/sign_in', params, dispatch);
    const body = await response.json();

    const { data } = body;

    const {
      submissions, productList, providers, companyLogo,
    } = data;

    const headers = {
      accessToken: response.headers.get('access-token'),
      client: response.headers.get('client'),
      uid: data.uid,
    };

    const { errors } = body;
    if (errors) {
      return false;
    }

    dispatch(setUser(data));
    dispatch(setProviderList(providers));
    dispatch(setSubmissions(submissions));
    dispatch(setAuthInfo(headers));
    dispatch(setPriceList(productList));
    dispatch(setCompanyLogoUrl(companyLogo));
    dispatch(resetActiveSubmission());
    dispatch(resetStoreProducts());

    return true;
  } catch (error) {
    return false;
  }
};

export const signOut = (user) => async (dispatch) => {
  try {
    const response = await destroy('/auth/sign_out', dispatch, credentials(user));
    const body = await response.json();

    const { success } = body;

    if (success) {
      dispatch(removeUser());
      dispatch(resetActiveSubmission());
      dispatch(resetStoreProducts());
    }

    return true;
  } catch (error) {
    return false;
  }
};

export const validateUserToken = (user) => async (dispatch) => {
  try {
    const response = await get('/auth/validate_token', dispatch, credentials(user));
    const body = await response.json();
    const { success, data } = body;

    if (success) {
      dispatch(setUser(data));
      return true;
    }

    return false;
  } catch (error) {
    return false;
  }
};

export const handleCreateNewCategory = (params, user) => async (dispatch) => {
  try {
    const response = await post('/category', params, dispatch, credentials(user));

    const body = await response.json();

    const { categories } = body;

    if (categories) dispatch(setUserCategories(categories));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleDeleteCategory = (params, user) => async (dispatch) => {
  try {
    const response = await post('/delete_category', params, dispatch, credentials(user));

    const body = await response.json();

    const { categories } = body;

    if (categories) dispatch(setUserCategories(categories));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleUpdateCategory = (id, params, user) => async (dispatch) => {
  try {
    const response = await put(`/category/${id}`, params, dispatch, credentials(user));

    const body = await response.json();

    const { categories } = body;

    if (categories) dispatch(setUserCategories(categories));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleSendEmailResetPassword = (email) => async (dispatch) => {
  try {
    const params = {
      email,
      redirect_url: redirectUrl,
    };
    const response = await post('/auth/password', params, dispatch);
    const body = await response.json();

    return body;
  } catch (error) {
    return false;
  }
};

export const handleResetPasswordWithEmail = (uid, client, accessToken, newPassword, confirmNewPassword) => async (dispatch) => {
  try {
    const params = {
      password: newPassword,
      password_confirmation: confirmNewPassword,
    };

    const header = {
      'access-token': accessToken,
      client,
      uid,
    };

    const response = await put('/auth/password', params, dispatch, header);
    const body = await response.json();

    return body;
  } catch (error) {
    return false;
  }
};

export const handleUserChangePassword = (params, user) => async (dispatch) => {
  try {
    const response = await put('/auth/password', params, dispatch, credentials(user));
    const body = await response.json();

    return body;
  } catch (error) {
    return false;
  }
};

export const handleUpdateCompany = (params, user) => async (dispatch) => {
  try {
    const response = await post('/update_user', params, dispatch, credentials(user));
    const body = await response.json();

    const { data } = body;
    if (data) dispatch(setUser(data));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleCreateSuggestion = (params, user) => async (dispatch) => {
  try {
    await post('/suggestion', params, dispatch, credentials(user));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleDeleteSubCategory = (id, user) => async (dispatch) => {
  try {
    const response = await destroy(`/sub_category/${id}`, dispatch, credentials(user));

    const body = await response.json();

    const { subCategories } = body;

    if (subCategories) dispatch(setUserSubCategories(subCategories));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleCreateNewSubCategory = (params, user) => async (dispatch) => {
  try {
    const response = await post('/sub_category', params, dispatch, credentials(user));

    const body = await response.json();

    const { subCategories } = body;

    if (subCategories) dispatch(setUserSubCategories(subCategories));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleDeleteProductListCategory = (id, user) => async (dispatch) => {
  try {
    const response = await destroy(`/product_list_category/${id}`, dispatch, credentials(user));

    const body = await response.json();

    const { productListCategories } = body;

    if (productListCategories) dispatch(setUserProductListCategories(productListCategories));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleCreateNewProductListCategory = (params, user) => async (dispatch) => {
  try {
    const response = await post('/product_list_category', params, dispatch, credentials(user));

    const body = await response.json();

    const { productListCategories } = body;

    if (productListCategories) dispatch(setUserProductListCategories(productListCategories));

    return true;
  } catch (error) {
    return false;
  }
};

export const handleUserOnBoarding = (params, user) => async (dispatch) => {
  try {
    const response = await post('/update_user', params, dispatch, credentials(user));
    const body = await response.json();

    const { data } = body;
    if (data) dispatch(setUser(data));

    return true;
  } catch (error) {
    return false;
  }
};

export const credentials = (user) => ({
  'access-token': user.accessToken,
  client: user.client,
  uid: user.uid,
});

export const setUserCategories = (categories) => ({
  type: 'SET_USER_CATEGORIES',
  categories,
});

export const setUserSubCategories = (subCategories) => ({
  type: 'SET_USER_SUB_CATEGORIES',
  subCategories,
});

export const setUserProductListCategories = (productListCategories) => ({
  type: 'SET_USER_PRODUCT_LIST_CATEGORIES',
  productListCategories,
});

export const setUser = (user) => ({
  type: 'SET_USER',
  user,
});

const removeUser = () => ({
  type: 'REMOVE_USER',
});

const setAuthInfo = (headers) => ({
  type: 'SET_AUTH_INFOS',
  accessToken: headers.accessToken,
  client: headers.client,
  uid: headers.uid,
});
