const initialState = {
  storeProducts: [],
};

const StoreProducts = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_STORE_PRODUCTS':
      return {
        storeProducts: action.storeProducts,
      };
    case 'RESET_STORE_PRODUCTS':
      return {
        storeProducts: initialState.storeProducts,
      };
    default:
      return state;
  }
};

export default StoreProducts;
