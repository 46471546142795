const initialState = {
  submissionList: [],
  activeSubmission: {
    submissionId: '-1',
    clientInfo: '',
    submissionProductList: [],
    submissionCategoryTotal: [],
    submissionTotal: '',
  },
};

const submissions = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_SUBMISSIONS':
      return {
        ...state,
        submissionList: action.submissionList,
      };
    case 'SET_ACTIVE_SUBMISSION':
      return {
        ...state,
        activeSubmission: action.submission,
      };
    case 'RESET_ACTIVE_SUBMISSION':
      return {
        ...state,
        activeSubmission: initialState.activeSubmission,
      };
    case 'SET_ACTIVE_SUBMISSION_AFTER_PRODUCT_DELETE_OR_CREATE':
      return {
        ...state,
        activeSubmission: {
          ...state.activeSubmission,
          submissionProductList: action.submission.submissionProductList,
          submissionTotal: action.submission.submissionTotal,
          submissionCategoryTotal: action.submission.submissionCategoryTotal,
        },
      };
    case 'SET_ACTIVE_SUBMISSION_AFTER_CLIENT_INFO_UPDATE':
      return {
        ...state,
        submissionList: state.submissionList.map((s) => (s.id === action.clientInfo.id
          ? { ...s, title: action.clientInfo.title } : s)),
        activeSubmission: {
          ...state.activeSubmission,
          clientInfo: action.clientInfo,
        },
      };
    case 'SET_ACTIVE_SUBMISSION_PRODUCTS_AFTER_PRIORITY_CHANGE':
      return {
        ...state,
        activeSubmission: {
          ...state.activeSubmission,
          submissionProductList: action.submission.submissionProductList,
        },
      };
    default:
      return state;
  }
};

export default submissions;
