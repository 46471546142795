const initialState = {
  events: [],
};

const Events = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_EVENT_LIST':
      return {
        events: action.events,
      };
    default:
      return state;
  }
};

export default Events;
