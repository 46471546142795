import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import {
  signIn, signUp, signOut, validateUserToken, handleCreateNewCategory,
  handleDeleteCategory, handleCreateSuggestion,
  handleUserChangePassword, handleSendEmailResetPassword,
  handleResetPasswordWithEmail, handleUpdateCategory, handleUpdateCompany,
  handleDeleteSubCategory, handleCreateNewSubCategory,
  handleDeleteProductListCategory, handleCreateNewProductListCategory,
  handleUserOnBoarding,
} from '../actions/user';

const mapStateToProps = ({ user }) => ({
  user,
});

const mapDispatchToProps = (dispatch) => bindActionCreators({
  signIn,
  signUp,
  signOut,
  validateUserToken,
  handleCreateSuggestion,
  handleCreateNewCategory,
  handleDeleteCategory,
  handleUserChangePassword,
  handleSendEmailResetPassword,
  handleResetPasswordWithEmail,
  handleUpdateCategory,
  handleUpdateCompany,
  handleDeleteSubCategory,
  handleCreateNewSubCategory,
  handleDeleteProductListCategory,
  handleCreateNewProductListCategory,
  handleUserOnBoarding,
}, dispatch);

const withUser = (WrappedComponent) => {
  class ConnectedComponent extends PureComponent {
    render() {
      return <WrappedComponent {...this.props} />;
    }
  }

  return connect(mapStateToProps, mapDispatchToProps)(ConnectedComponent);
};

export default withUser;
