const initialState = {
  url: null,
};

const Plans = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PLANS_URL':
      return {
        url: action.url,
      };
    default:
      return state;
  }
};

export default Plans;
