const initialState = {
  url: null,
};

const companyLogo = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_COMPANY_LOGO_URL':
      return {
        url: action.url,
      };
    default:
      return state;
  }
};

export default companyLogo;
