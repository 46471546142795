import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import withUser from './containers/withUser';
import { validateTrialDate } from './utils/validateTrialDate';

class ProtectedRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isAuthenticated: null,
      isLoading: true,
      isSubscribe: null,
      needRenewal: null,
    };
  }

  async componentDidMount() {
    const { user, validateUserToken } = this.props;

    const response = await validateUserToken(user);

    if (response) {
      const {
        subscribe, trial_end: trialEnd, need_renewal: needRenewal,
      } = user;

      const isTrial = validateTrialDate(trialEnd);

      this.setState({
        isAuthenticated: true,
        isSubscribe: subscribe,
        isLoading: false,
        isTrial,
        needRenewal,
      });
    } else {
      this.setState({ isAuthenticated: false, isLoading: false });
    }
  }

  render() {
    const { component: Component, ...rest } = this.props;
    const {
      isLoading, isAuthenticated, isSubscribe, isTrial, needRenewal,
    } = this.state;

    if (isLoading) {
      return null;
    }
    if (!isLoading) {
      return (
        <div>
          <Route
            {...rest}
            render={(props) => {
              if (isAuthenticated) {
                if (isTrial || (isSubscribe && !needRenewal)) return <Component {...props} />;
                if (!isTrial && !isSubscribe && !needRenewal) return <Redirect to="/subscribe" />;
                if (!isTrial && isSubscribe && needRenewal) return <Redirect to="/renewal" />;
              } else {
                return <Redirect to="/login" />;
              }
            }}
          />
        </div>
      );
    }
  }
}

export default withUser(ProtectedRoute);
