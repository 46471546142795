import moment from 'moment';

export const validateTrialDate = (trialEnd) => {
  if (trialEnd) {
    const trialDate = moment(trialEnd, 'YYYY-MM-DD');
    const formatTrialDate = trialDate.format('YYYY-MM-DD');
    const todayDate = moment().format('YYYY-MM-DD');

    return moment(todayDate).isBefore(formatTrialDate);
  }
  return false;
};
