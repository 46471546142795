const initialState = {
  priceList: [],
};

const priceList = (state = initialState, action) => {
  switch (action.type) {
    case 'SET_PRICE_LIST':
      return {
        priceList: action.priceList,
      };
    default:
      return state;
  }
};

export default priceList;
