import React, { Component } from 'react';
import {
  BrowserRouter, Route, Switch,
} from 'react-router-dom';
import './utils/App.scss';
import { Provider } from 'react-redux';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { PersistGate } from 'redux-persist/integration/react';
import ProtectedRoute from './protectedRoute';
import ProtectedRouteSubscribe from './protectedRouteSubscribe';
import { store, persistor } from './store/store';
import ProtectedRouteRenewal from './protectedRouteRenewal';
import { StripePromise } from './utils/configuration';

const loading = () => <div className="text-primary row"><div style={{ position: 'fixed', top: '50%', left: '50%' }} className="spinner-border" role="status" /></div>;

const DefaultLayout = React.lazy(() => import('./containers/DefaultLayout'));
const ForgotPassword = React.lazy(() => import('./components/views/ResetPasswordResetStep/ResetPasswordResetStep'));
const ResetPassword = React.lazy(() => import('./components/views/ResetPasswordEmailStep/ResetPasswordEmailStep'));
const Login = React.lazy(() => import('./components/views/Login/Login'));
const Register = React.lazy(() => import('./components/views/Register/Register'));
const Page404 = React.lazy(() => import('./components/views/Page404/Page404'));
const Subscribe = React.lazy(() => import('./components/views/Subscribe/Subscribe'));
const RenewalSubscription = React.lazy(() => import('./components/views/RenewalSubscription/RenewalSubscription'));
const EmailValidationSend = React.lazy(() => import('./components/views/EmailValidationSend/EmailValidationSend'));
const EmailResetPasswordSend = React.lazy(() => import('./components/views/EmailResetPasswordSend/EmailResetPasswordSend'));
const SubscribeMessage = React.lazy(() => import('./components/views/SubscribeMessage/SubscribeMessage'));
const CancelSubscribeMessage = React.lazy(() => import('./components/views/CancelSubscriptionMessage/CancelSubscriptionMessage'));

const stripePromise = loadStripe(StripePromise);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <Elements options={{ locale: 'fr' }} stripe={stripePromise}>
            <BrowserRouter>
              <React.Suspense fallback={loading()}>
                <Switch>
                  <Route exact path="/login" name="Login Page" render={(props) => <Login {...props} />} />
                  <Route exact path="/register" name="Register Page" render={(props) => <Register {...props} />} />
                  <Route exact path="/404" name="Page 404" render={(props) => <Page404 {...props} />} />
                  <Route exact path="/forgot_password" name="Forgot password" render={(props) => <ForgotPassword {...props} />} />
                  <Route exact path="/reset_password" name="Reset password" render={(props) => <ResetPassword {...props} />} />
                  <Route exact path="/email_send" name="Email Send" render={(props) => <EmailValidationSend {...props} />} />
                  <Route exact path="/email_reset_send" name="Email Send" render={(props) => <EmailResetPasswordSend {...props} />} />
                  <Route exact path="/subscribe_message" name="Subscribe message" render={(props) => <SubscribeMessage {...props} />} />
                  <Route exact path="/cancel_subscribe_message" name="Subscribe message" render={(props) => <CancelSubscribeMessage {...props} />} />
                  <ProtectedRouteSubscribe exact path="/subscribe" name="Subscribe" component={Subscribe} />
                  <ProtectedRouteRenewal exact path="/renewal" name="Renewal" component={RenewalSubscription} />
                  <ProtectedRoute path="/" component={DefaultLayout} />
                </Switch>
              </React.Suspense>
            </BrowserRouter>
          </Elements>
        </PersistGate>
      </Provider>
    );
  }
}

export default App;
